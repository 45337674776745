<template>
  <evalmee-chip
    color="blue"
    small
    label
    icon="mdi-creation-outline"
  >
    {{ $t('live.correction.generalFeedback.aiGenerated') }}
  </evalmee-chip>
</template>


<script>
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"

export default {
  name: "FeedbackChip",
  components: { EvalmeeChip },
}
</script>

<style scoped>

</style>
