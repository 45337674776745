<template>
  <v-card
    flat
    tile
    v-if="answer"
    v-click-outside="() => focused = false"
  >
    <v-expand-transition>
      <div v-show="!inEmptyState">
        <div
          class="pa-2"
        >
          <div
            class="d-flex align-center"
          >
            <eva-big-icon
              icon="mdi-account"
              :size="20"
              color="blue"
            />
            <span
              class="ml-2"
              :class="{
                'subtitle-2': !inEmptyState,
                'body-2': inEmptyState,
                'grey--text': inEmptyState,
              }"
            >
              {{ inEmptyState ?
                $t('live.correction.answer.customFeedback.addLabel') :
                $t('live.correction.answer.customFeedback.label') }}
            </span>
            <v-spacer />
            <feedback-chip
              class="ml-2"
              v-if="answerFeedback?.status === 'ai_generated'"
            />
          </div>
        </div>
        <v-divider />
      </div>
    </v-expand-transition>
    <div @click="setFocus">
      <div class="d-flex">
        <div
          class="pa-2 mt-1"
          v-if="inEmptyState"
        >
          <eva-big-icon
            icon="mdi-account"
            :size="20"
            color="blue"
          />
        </div>
        <div
          class="flex-grow-1 mt-1"
        >
          <v-expand-transition>
            <text-editor
              v-show="!pendingFeedbackGeneration"
              v-if="!(pendingFeedbackGeneration && (!answerFeedback || answerFeedback.blank_content ))"
              v-model="comment"
              :placeholder="$t('live.correction.answer.customFeedback.addLabel')"
              ref="textEditor"
              :style="{
                'min-height': inEmptyState ? '10px !important' : 'none',
                'max-height': inEmptyState ? '10px !important' : 'none',
              }"
              :class="{
                'mx-n7': inEmptyState,
                'my-n2': inEmptyState,
                'mx-2': !inEmptyState,

              }"
            />
          </v-expand-transition>
          <v-expand-transition>
            <div
              v-if="pendingFeedbackGeneration"
              class="mx-8 my-4"
            >
              <v-skeleton-loader
                v-for="i in 7"
                :key="i"
                type="text"
                class="mb-0"
                :loading="false"
                :width="`${
                  Math.floor(Math.random() * 50) + 50
                }%`"
              />
            </div>
          </v-expand-transition>
        </div>
        <div
          class="pa-2"
          v-if="answer.feedback_generation_allowed"
        >
          <eva-button
            dense
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.buttonTooltip')"
            class="pa-2"
            @click.stop="() => $refs.generateFeedbackMenu?.generateFeedback()"
          >
            <v-icon small>
              mdi-creation-outline
            </v-icon>
          </eva-button>
        </div>
      </div>
      <div v-show="!inEmptyState">
        <generate-feedback-menu
          :answer="answer"
          @insert="(content) => comment = [(comment || '') + content].join(`\n`)"
          @replace="(content) => comment = content"
          @update-grade-suggestion="gradeSuggestion = $event"
          @generating="pendingFeedbackGeneration = $event"
          ref="generateFeedbackMenu"
          class="mx-2"
        />
        <v-card-actions class="px-0">
          <v-spacer />
          <eva-button
            @click="approve"
            dense
            v-if="comment === answerFeedback?.user_content && answerFeedback?.status === 'ai_generated' && !pendingFeedbackGeneration"
            class="mr-2"
            icon-left="mdi-check-decagram-outline"
          >
            {{ $t("evalmee.home.teacher.openQuestionGrader.validateFeedback") }}
          </eva-button>
          <eva-button
            @click="saveFeedback"
            dense
            primary
            v-if="comment !== answerFeedback?.user_content"
            class="mr-2"
          >
            {{ $t("evalmee.home.teacher.openQuestionGrader.saveComment") }}
          </eva-button>
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import GenerateFeedbackMenu from "@/components/questions/GenerateFeedbackMenu.vue"
import getFeatureMixin from "@/components/shared/get_feature_mixin"
import TextEditor from "@/components/shared/text_editor/text_editor.vue"
import FeedbackChip from "@/components/questions/feedbackChip.vue"
import EvaBigIcon from "@/components/shared/eva-big-icon.vue"

export default {
  name: "AnswerFeedbackEditor",
  components: { EvaBigIcon, FeedbackChip, TextEditor, GenerateFeedbackMenu },
  mixins: [getFeatureMixin],
  props: {
    answer: { type: Object, required: true },
  },
  data: () => ({
    comment: "",
    pendingFeedbackGeneration: false,
    focused: false,
  }),
  computed: {
    ...mapGetters(["answersFeedbackByAnswerId"]),
    answerFeedback() {
      return this.answersFeedbackByAnswerId(this.answer.id)
    },
    inEmptyState() {
      if(this.focused) return false
      if(this.pendingFeedbackGeneration) return false
      if(!this.answerFeedback) return true
      if(this.comment !== this.answerFeedback?.user_content) return false
      return this.answerFeedback.blank_content
    },
  },
  methods: {
    ...mapActions(["newAppNotification", "createFeedbackForAnswer", "approveFeedback"]),
    saveFeedback() {
      this.createFeedbackForAnswer({
        answer: this.answer, feedbackContent: this.comment,
      })
    },
    approve() {
      this.approveFeedback({ feedback: this.answerFeedback })
    },
    setFocus() {
      this.focused = true
      this.$nextTick(() => {
        this.$refs.textEditor.focus()
      })
    },
  },
  watch: {
    "answerFeedback.user_content": {
      handler(val) {
        this.comment = val
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
</style>
