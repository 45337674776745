<template>
  <v-card flat>
    <v-card-title>
      <template v-if="submission.general_state !== 'pending'">
        <evalmee-chip
          v-if="submission.general_state === 'done'"
          :color="color(submission.report?.overall_match_percentage)"
          class="mr-2"
        >
          {{ submission.report?.overall_match_percentage }}%
        </evalmee-chip>
        {{ $t("quiz.settings.similarityCheck.submission.title") }}
        <v-spacer />
        <v-btn
          v-if="submission.general_state === 'done'"
          text
          small
          :href="answer?.links?.similarity_tool"
          target="_blank"
        >
          Turnitin
          <v-icon
            small
            rigth
          >
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </template>
      <template v-else>
        {{ $t("quiz.settings.similarityCheck.submission.inProgress") }}
        <evalmee-chip
          small
          class="ml-4"
          v-tooltip="submission.state"
        >
          {{ $t(`quiz.settings.similarityCheck.submission.states.${submission.general_state}`) }}
        </evalmee-chip>
      </template>
    </v-card-title>
    <v-card-text v-if="submission.too_little_text">
      {{ $t("quiz.settings.similarityCheck.submission.errors.tooLittleText") }}
    </v-card-text>

    <template v-if="submission.state === 'report_ready'">
      <v-list dense>
        <v-list-item
          v-for="(match, index) in submission.report.top_matches"
          :key="index"
        >
          <v-list-item-avatar>
            <evalmee-chip
              :color="color(match.percentage)"
              small
            >
              {{ Math.round(match.percentage) }}%
            </evalmee-chip>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ match.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ match.source_type }} - {{ match.matched_word_count_total }} words
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
    <!--    {{ submission.report }}-->
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import EvalmeeChip from "../../shared/evalmee_chip.vue"

export default {
  name: "TurnitinAnswerReport",
  components: { EvalmeeChip },
  props: {
    answer: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["similarityCheckSubmissionsByAnswer"]),
    submission() {
      return this.similarityCheckSubmissionsByAnswer(this.answer.id)
    },
  },
  methods: {
    color(percentage) {
      if (percentage < 10) return "green"
      if (percentage < 30) return "orange"
      return "red"
    },
  },
}
</script>

<style scoped>

</style>
